import React from 'react'

const Sources = () => {
  return (
    <p className="sources">
      <span className="sources__title">Quellen:</span>
      Disclaimer eRecht24, Disclaimer von eRecht24, dem Portal zum Internetrecht
      von Rechtsanwalt Sören Siebert
    </p>
  )
}

export default React.memo(Sources)
